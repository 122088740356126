import $http from '../axios/interface.js';
import { fquery } from './common.js'
import { Toast } from 'vant'

const urlObj = fquery(window.location.hash.split('?')[1])

const toAuth = async () => {
  console.log(urlObj, 'obj')
  if (urlObj.code) {
    Toast.loading({
      message: '加载中',
      forbidClick: true,
      duration: 0
    });
    let res = await $http.userApi.wxLogin({code: urlObj.code})
    console.log(res, 'reswx')
    if (res.code === 200) {
      Toast.clear()
      window.localStorage.setItem('userData', JSON.stringify(res.msg || {}))
      let url = (urlObj.state || 'home/index').replace(/-/g, '/')
      window.location.replace(window.location.origin + '/#/' + url)
    }
  }
}

export default toAuth