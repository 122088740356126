/**
 * 首页
 * Created by wqs565 on 2020/5/18 14:23.
 */
import axios from '../../api'
// 预约试听
export const appointment = data => {
  return axios({
    url: 'gateway/index/appointment',
    method: 'post',
    data
  })
}

// 课程详情
export const courseDetail = data => {
  return axios({
    url: 'gateway/index/course/detail',
    method: 'post',
    data
  })
}

// 意见反馈
export const feedback = data => {
  return axios({
    url: 'gateway/index/feedback',
    method: 'post',
    data
  })
}

// 首页banner和分类信息
export const bannerInfo = data => {
  return axios({
    url: 'gateway/index/info',
    method: 'post',
    data
  })
}

// 门店详情
export const schoolDetail = data => {
  return axios({
    url: 'gateway/index/school/detail',
    method: 'post',
    data
  })
}

// 门店列表
export const schoolList = data => {
  return axios({
    url: 'gateway/index/school/list',
    method: 'post',
    data
  })
}

// 搜索历史
export const searchHistory = data => {
  return axios({
    url: 'gateway/index/search/list',
    method: 'post',
    data
  })
}
// 清空搜索历史
export const searchDelete = data => {
  return axios({
    url: 'gateway/index/search/delete',
    method: 'post',
    data
  })
}