/*
 * @Author: wangqs 
 * @Date: 2021-06-21 16:20:34 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-24 11:35:35
 */
export default [
  {
    path: '/order/index',
    name: 'order',
    component: () => import(/* webpackChunkName: "order-list" */ '@/views/order/index.vue'),
    meta: {
      title: '个人分期'
    }
  },
  {
    path: '/order/editInfo',
    name: 'editInfo',
    component: () => import(/* webpackChunkName: "order-edit-info" */ '@/views/order/editInfo.vue'),
    meta: {
      title: '修改分期信息'
    }
  },
  {
    path: '/order/sign',
    name: 'sign',
    component: () => import(/* webpackChunkName: "order-sign" */ '@/views/order/sign.vue'),
    meta: {
      title: '签署合同'
    }
  },
  {
    path: '/order/fullOrder',
    name: 'fullOrder',
    component: () => import(/* webpackChunkName: "full-order" */ '@/views/order/fullOrder.vue'),
    meta: {
      title: '全款订单'
    }
  },
  {
    path: '/order/realAuth',
    name: 'realAuth',
    component: () => import(/* webpackChunkName: "real-auth" */ '@/views/school/realAuth.vue'),
    meta: {
      title: '身份认证'
    }
  },
  {
    path: '/order/addBank',
    name: 'addBank',
    component: () => import(/* webpackChunkName: "add-bank" */ '@/views/school/addBank.vue'),
    meta: {
      title: '添加银行卡'
    }
  },
  {
    path: '/order/periodAddBank',
    name: 'addBankA',
    component: () => import(/* webpackChunkName: "add-org-period" */ '@/views/orgPeriod/addBank.vue'),
    meta: {
      title: '添加银行卡'
    }
  },
  {
    path: '/order/success',
    name: 'orderSuccess',
    component: () => import(/* webpackChunkName: "order-success" */ '@/views/order/success.vue'),
    meta: {
      title: '提交审核'
    }
  },
]