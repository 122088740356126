/**
 * 用户
 * Created by wqs565 on 2020/5/18 14:23.
 */
import axios from '../../api'
// 绑卡
export const bankBind = data => {
  return axios({
    url: 'gateway/user/bank/bind',
    method: 'post',
    data
  })
}

// 新版绑卡
export const orderBankBind = data => {
  return axios({
    url: 'gateway/order/bind/card',
    method: 'post',
    data
  })
}

// 绑卡确认
export const bankBindConfirm = data => {
  return axios({
    url: 'gateway/user/bank/bind/confirm',
    method: 'post',
    data
  })
}

// V2绑卡
export const bankOrderBindConfirm = data => {
  return axios({
    url: 'gateway/order/bind/card/confirm',
    method: 'post',
    data
  })
}

// 银行卡列表
export const bankList = data => {
  return axios({
    url: 'gateway/user/bank/list',
    method: 'post',
    data
  })
}

// 解绑银行卡 
export const bankUnbind = data => {
  return axios({
    url: 'gateway/user/bank/unbind',
    method: 'post',
    data
  })
}

// 换绑第1步 
export const bankChange = data => {
  return axios({
    url: 'gateway/user/bank/change',
    method: 'post',
    data
  })
}

// 换绑第2步
export const bankChangeConfirm = data => {
  return axios({
    url: 'gateway/user/bank/change/confirm',
    method: 'post',
    data
  })
}

// 新增学员
export const childAdd = data => {
  return axios({
    url: 'gateway/user/child/add',
    method: 'post',
    data
  })
}

// 删除学员
export const childDel = data => {
  return axios({
    url: 'gateway/user/child/del',
    method: 'post',
    data
  })
}

// 修改学员
export const childEdit = data => {
  return axios({
    url: 'gateway/user/child/edit',
    method: 'post',
    data
  })
}

// 孩子列表
export const userChildrenList = data => {
  return axios({
    url: 'gateway/user/children',
    method: 'post',
    data
  })
}

// 用户个人信息
export const userInfo = data => {
  return axios({
    url: 'gateway/user/info',
    method: 'post',
    data
  })
}

// 绑定手机
export const mobileBind = data => {
  return axios({
    url: 'gateway/user/mobile/bind',
    method: 'post',
    data
  })
}

// 发送绑定手机号
export const sendCode = data => {
  return axios({
    url: 'gateway/user/send/sms',
    method: 'post',
    data
  })
}

// wx 登录
export const wxLogin = data => {
  return axios({
    url: 'gateway/wx/login?code=' + data.code,
    method: 'get',
    params: data
  })
}

// 上传
export const upload = data => {
  return axios({
    url: 'gateway/img/upload',
    method: 'get',
    data
  })
}

// 用户详情
export const userDetail = data => {
  return axios({
    url: 'gateway/user/detail',
    method: 'post',
    data
  })
}

// 编辑个人信息
export const editInfo = data => {
  return axios({
    url: 'gateway/user/info/edit',
    method: 'post',
    data
  })
}

// 获取签名
export const signatureGet = data => {
  return axios({
    url: 'gateway/index/signature/get',
    method: 'post',
    data
  })
}