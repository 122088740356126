/*
 * @Author: wangqs 
 * @Date: 2021-06-21 16:20:34 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-02-09 10:05:56
 */
export default [
  {
    path: '/home/index',
    name: 'home-index',
    component: () => import(/* webpackChunkName: "home-index" */ '@/views/home/index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/home/search',
    name: 'search-index',
    component: () => import(/* webpackChunkName: "search-index" */ '@/views/home/search/index.vue'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/home/type',
    name: 'type-index',
    component: () => import(/* webpackChunkName: "type-index" */ '@/views/home/type/index.vue'),
    meta: {
      title: '分类'
    }
  }
]