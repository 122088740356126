/*
 * @Author: wangqs 
 * @Date: 2021-06-21 16:20:34 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-07-21 18:47:02
 */
export default [
  {
    path: '/school/detail',
    name: 'school-index',
    component: () => import(/* webpackChunkName: "school-index" */ '@/views/school/index.vue'),
    meta: {
      title: '门店详情'
    }
  },
  {
    path: '/school/courseDetail',
    name: 'course-detail',
    component: () => import(/* webpackChunkName: "courseDetail" */ '@/views/school/courseDetail.vue'),
    meta: {
      title: '课程详情'
    }
  },
  {
    path: '/school/coursePay',
    name: 'course-pay-detail',
    component: () => import(/* webpackChunkName: "coursePay" */ '@/views/school/coursePay.vue'),
    meta: {
      title: '全款支付'
    }
  },
  {
    path: '/school/basicInfo',
    name: 'basic-info',
    component: () => import(/* webpackChunkName: "bsicInfo" */ '@/views/school/info/basicInfo.vue'),
    meta: {
      title: '基本信息'
    }
  },
  {
    path: '/school/authInfo',
    name: 'auth-info',
    component: () => import(/* webpackChunkName: "authInfo" */ '@/views/school/info/authInfo.vue'),
    meta: {
      title: '授权信息'
    }
  },
  {
    path: '/school/uploadInfo',
    name: 'last-info',
    component: () => import(/* webpackChunkName: "uploadInfo" */ '@/views/school/info/lastInfo.vue'),
    meta: {
      title: '上传资料'
    }
  },
  {
    path: '/school/orderPay',
    name: 'order-info',
    component: () => import(/* webpackChunkName: "orderPay" */ '@/views/school/fqPay.vue'),
    meta: {
      title: '分期支付'
    }
  }
]